import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import {
  ActivateAcquisti,
  ActivateAdmin,
  ActivateCamera,
  ActivateEcommerce,
  ActivateFormazione,
  ActivateInterventi,
  ActivateListini,
  ActivatePrimaNota,
  ActivateProgetti,
  ActivateRma,
  ActivateScadenzario,
  ActivateSettings,
  ActivateShared,
  ActivateSpedizioni,
  ActivateStampe,
  ActivateTask,
  ActivateTicket,
  AuthGuard
} from './Login/_guards/AuthGuard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/camera/articolo', pathMatch: 'full' },
      {
        path: 'listautenti',
        loadChildren: () => import('./Admin/Admin.module').then(m => m.AdminModule),
        canActivate: [ActivateAdmin]
      },{
        path: 'task',
        loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
        canActivate: [ActivateTask]
      },{
        path: 'dashboard',
        loadChildren: () => import('./shared/Shared.module').then(m => m.SharedModule),
        canActivate: [ActivateShared]
      },{
        path: 'progetti',
        loadChildren: () => import('./Progetti/Progetti.module').then(m => m.ProgettiModule),
        canActivate: [ActivateProgetti ]
      }, {
        path: 'stampe',
        loadChildren: () => import('./Stampe/Stampe.module').then(m => m.StampeModule),
        canActivate: [ActivateStampe]
      }, {
        path: 'interventi',
        loadChildren: () => import('./Interventi/Interventi.module').then(m => m.InterventiModule),
        canActivate: [ActivateInterventi]
      }, {
        path: 'scadenzario',
        loadChildren: () => import('./Scadenzario/Scadenzario.module').then(m => m.ScadenzarioModule),
        canActivate: [ActivateScadenzario]
      }, {
        path: 'primanota',
        loadChildren: () => import('./Prima_Nota/Prima_Nota.module').then(m => m.Prima_NotaModule),
        canActivate: [ActivatePrimaNota]
      }, {
        path: 'spedizioni',
        loadChildren: () => import('./Spedizioni/Spedizioni.module').then(m => m.SpedizioniModule),
        canActivate: [ActivateSpedizioni]
      }, {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [ActivateSettings]
      }, {
        path: 'formazione',
        loadChildren: () => import('./formazione/formazione.module').then(m => m.FormazioneModule),
        canActivate: [ActivateFormazione]
      }, {
        path: 'bbb',
        loadChildren: () => import('./big-blue-button/big-blue-button.module').then(m => m.BigBlueButtonModule),
        canActivate: [AuthGuard]
      }, {
        path: 'ticket',
        loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule),
        canActivate: [ActivateTicket]
      },
      {
        path: 'ecom',
        loadChildren: () => import('./ecommerce/ecom.module').then(m => m.EcomModule),
        canActivate: [ActivateEcommerce]
      },{
        path: 'rma',
        loadChildren: () => import('./rma/rma.module').then(m => m.RMAModule),
        canActivate: [ActivateRma]
      },{
        path: 'Cliente',
        loadChildren: () => import('./Admin/Admin.module').then(m => m.AdminModule),
        canActivate: [ActivateAdmin]
      },{
        path: 'listino',
        loadChildren: () => import('./listino/listino.module').then(m => m.ListinoModule),
        canActivate: [ActivateListini]
      },
      {
        path:'acquisti',
        loadChildren: () => import('./acquisti/acquisti.module').then(m => m.AcquistiModule),
        canActivate: [ActivateAcquisti]
      }
    ]

  },
  {
    path: 'Login',
    loadChildren: () => import('./Login/Login.module').then(m => m.LoginModule)
  }, {
    path: 'confirmation',
    loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule),
  },
  {
    path:'camera',
    loadChildren: () => import('./camera/camera.module').then(m => m.CameraModule),
    canActivate: [ActivateCamera]
  },
  {
    path: '**',
    //redirectTo: "/Login"
    redirectTo: "/camera/articolo"
  }
];
